import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layouts/Layout";
import { AppVideo } from "../types";

const Video = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { video }: { video: AppVideo, categoryId: number } = state;

    return (
        <Layout>
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                    <h2 className="text-2xl text-left font-semibold text-[#3d3d3d]">
                        <span className="opacity-70">Video:</span> {video.title}
                    </h2>
                    <div className="flex items-center justify-start gap-4">
                        <button className="uppercase font-semibold text-[13.3333px] hover:bg-[#00475D] transition-all bg-[#204D74] text-white rounded-full px-6 py-2"
                            onClick={() => navigate(-1)}>
                            GO BACK
                        </button>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <video controls autoPlay className="aspect-video bg-slate-300 rounded">
                        <source src={video.url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="flex items-center justify-center gap-4">
                        <div className="text-base text-slate-800">{video.description}</div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Video;