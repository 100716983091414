import { useEffect, useState } from 'react';
import Layout from '../Layouts/Layout';
import Categories from '../Components/Categories';
import Videos from '../Components/Videos';
import { AppVideoCategoryApiResponse, VideoCategory } from '../types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_REQUEST_ARGS } from '../config';
import api from '../Services/api';
import he from 'he';

const Main = () => {
    const [category, setCategory] = useState<VideoCategory>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    const onSelectCategory = (category?: VideoCategory) => {
        if (category) {
            navigate(`/category/${category.id}`, { state: { category } });
        } else {
            navigate('/');
        }
    }

    const { key, state } = useLocation();
    const { categoryId } = useParams();

    const goBack = () => {
        setIsLoading(true);
        navigate(-1);
    }

    useEffect(() => {
        if (state) {
            setCategory(state.category);
            setIsLoading(false);
        } else if (categoryId) {
            const categoryResponseParams = new URLSearchParams({
                ...DEFAULT_REQUEST_ARGS,
            });

            api.get<AppVideoCategoryApiResponse>(`/app-video-category/${categoryId}`, { params: categoryResponseParams }).then((categoryResponse) => {
                setCategory({
                    id: categoryResponse.data.id,
                    name: he.decode(categoryResponse.data.name),
                    parent: categoryResponse.data.parent,
                    order: typeof categoryResponse.data.acf.order_number === 'number' ? categoryResponse.data.acf.order_number : parseInt(categoryResponse.data.acf.order_number),
                });
                setIsLoading(false);
            });
        } else {
            setCategory(undefined);
            setIsLoading(false);
        }
    }, [state, categoryId]);

    if (categoryId && !category) {
        return null;
    }

    return (
        <Layout>
            <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-1">
                    <h2 className="text-2xl text-left font-semibold text-[#3d3d3d]">
                        {category ? category.name : 'Categories'}
                    </h2>
                    {(category && key !== 'default') && (
                        <div className="flex items-left justify-start gap-4 mt-3">
                            <button className="uppercase font-semibold text-[13.3333px] hover:bg-[#00475D] transition-all bg-[#204D74] text-white rounded-full px-6 py-2"
                                onClick={goBack}>
                                GO BACK
                            </button>
                        </div>
                    )}
                </div>
                <Categories parent={category} onSelectCategory={(onSelectCategory)} />
                <Videos isParentLoading={isLoading} categoryId={category?.id || 0} />
            </div>
        </Layout>
    )
};

export default Main;