import { memo, useCallback, useEffect, useState } from "react";
import { AppVideoApiResponse, Video } from "../types";
import { API_BASE, DEFAULT_REQUEST_ARGS } from "../config";
import axios from "axios";
import * as he from "he";
import Play from "./Icons/Play";
import { useNavigate } from "react-router-dom";
import VideoThumbnailSkeleton from "./VideoThumbnailSkeleton";

interface VideosProps {
    categoryId: number;
    isParentLoading?: boolean;
    onVideosLoaded?: (videos: Video[]) => void;
}

const Videos = ({ categoryId, isParentLoading, onVideosLoaded }: VideosProps) => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const loadVideos = useCallback(async (categoryId: number) => {
        setIsLoading(true);
        try {
            const videoResponseParams = new URLSearchParams({
                ...DEFAULT_REQUEST_ARGS,
                'app-video-category': categoryId.toString(),
                orderby: 'title',
                order: 'asc',
            });

            const videoResponse = await axios.get<AppVideoApiResponse[]>(`${API_BASE}/app-video`, { params: videoResponseParams });

            const videosArray: Video[] = [];
            videoResponse.data.forEach((video) => {
                videosArray.push({
                    id: video.id,
                    title: he.decode(video.title.rendered),
                    url: video.acf.video.url,
                    mime_type: video.acf.video.mime_type,
                    thumbnail: video.acf.thumbnail,
                    duration: video.acf.video.duration,
                    order: getNumberFromVideoFilename(video.acf.video.filename),
                });
            });

            videosArray.sort((a, b) => (a.order > b.order) ? 1 : -1);

            setVideos(videosArray);

        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if(onVideosLoaded) {
            onVideosLoaded(videos);
        }
    }, [onVideosLoaded, videos]);

    const getNumberFromVideoFilename = (filename: string): number => {
        const regex = /_(\d+)_/;
        const match = filename.match(regex);
        if (match) {
            return parseInt(match[1]);
        }

        return 0;
    }

    useEffect(() => {
        setIsLoading(true);
        loadVideos(categoryId);
    }, [categoryId, loadVideos]);



    if (isLoading || isParentLoading) {
        return (
            <div className="grid sm:grid-cols-2 gap-8">
                <VideoThumbnailSkeleton />
                <VideoThumbnailSkeleton />
            </div >
        )
    }

    if (videos.length === 0) {
        return null;
    }

    return (
        <div className="grid sm:grid-cols-2 gap-8">
            {videos.map((video) => (
                <div key={`video-${video.id}`} className="flex flex-col gap-2">
                    <button
                        type="button"
                        className="relative group flex items-center justify-center group aspect-video bg-slate-100 rounded"
                        onClick={() => navigate(`/video`, { state: { video, categoryId } })}
                    >
                        <img src={video.thumbnail} alt="" className="absolute inset-0 z-0 object-cover w-full h-full" loading="lazy" />
                        <div className="absolute inset-0 bg-slate-800/30 transition-all group-hover:bg-transparent"></div>
                        <div className="absolute rounded bg-slate-800/80 text-white top-4 right-4 px-2 py-0.5 text-xs z-30">{video.duration}</div>
                        <Play className="opacity-60 w-20 h-20 text-white group-hover:opacity-90 transition-all" />
                    </button>
                    <div className="flex items-center justify-center gap-4">
                        <div className="text-base font-medium text-slate-800">{video.title}</div>
                    </div>
                </div>
            ))}
        </div>
    )
};

export default memo(Videos);