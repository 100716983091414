interface LayoutProps {
    header?: React.ReactNode;
    children: React.ReactNode;
}

const Layout = ({ header, children }: LayoutProps) => {
    return (
        <div className="min-h-screen">
            {header}
            <main className="container mx-auto py-8">
                {children}
            </main>
        </div>
    )
};

export default Layout;